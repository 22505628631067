import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import s from '../styles/summer.scss';
import { livWalk } from '../assets/photo-urls';
import classNames from 'classnames';
import Select, { SingleValue } from 'react-select';
import Loader from '../assets/svg/dot-loader.svg';

interface Option {
  value: string;
  label: string;
}
const options = [
  { value: '1', label: '1 guest' },
  { value: '2', label: '2 guests' },
  { value: '3', label: '3 guests' },
  { value: '4', label: '4 guests' },
  { value: '5', label: '5 guests' },
  { value: '6', label: '6 guests' },
  { value: '7', label: '7 guests' },
  { value: '8', label: '8 guests' },
  { value: '9', label: '9 guests' },
  { value: '10', label: '10 guests' },
  { value: '11', label: '11 guests' },
  { value: '12', label: '12 guests' },
  { value: '13', label: '13 guests' },
];

// tslint:disable no-default-export
export default () => {
  const [selected, setSelected] = useState('1');
  const [loading, setLoading] = useState(false);

  const handleOnChange = (val: SingleValue<Option>) => {
    if (val?.value) {
      setSelected(val.value);
    }
  };

  const handleOnBookClick = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://ruadeseo.herokuapp.com', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: `mutation CreatePaymentLink($quickPayInput: QuickPayInput!){createQuickPayLink(quickPayInput: $quickPayInput) {paymentLink}}`,
          variables: {
            quickPayInput: {
              name: `Summer Trip - ${+selected} ${+selected === 1 ? 'guest' : 'guests'}`,
              priceInMinorUnits: 32500,
              quantity: +selected,
            },
          },
        }),
      });
      const { data } = await response.json();
      if (data?.createQuickPayLink?.paymentLink) {
        const url = data.createQuickPayLink.paymentLink;
        window.open(url, '__blank');
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      <Helmet title="Valle de Guadalupe Summer Trip" />
      <section className={s.section}>
        <div className="flex_center_row" style={{ paddingTop: '110px' }}>
          <div className={s.left}>
            <div className={s.heroImg} style={{ backgroundImage: `url(${livWalk})` }}></div>
          </div>
          <div className={s.right}>
            <h1 className="garamond_61 margin_bottom_4">Exclusive Summer Trip</h1>
            <h2 className="garamond_30 margin_bottom_16">Saturday, September 3rd</h2>
            <h2 className="garamond_34 margin_bottom_8">$325 per/person</h2>
            <h3 className="brandon_12_bold margin_bottom_16">Limited spots available</h3>
            <div className="margin_bottom_32">
              <p className="brandon_18">
                <span className="brandon_18_bold">9:00am - </span>Depart from San Diego
              </p>
              <p className="brandon_18">• Wine tasting and vineyard tour at Relieve</p>
              <p className="brandon_18">• Wine tasting and lunch at Solar Fortún</p>
              <p className="brandon_18">• Wine tasting at Bodegas Xolo</p>
              <p className="brandon_18">• Dinner at Bruma Wine Garden</p>
              <p className="brandon_18">• Depart back to San Diego</p>
              <p className="brandon_18">
                <span className="brandon_18_bold">8:00pm - </span>
                Arrive at border crossing
              </p>
              <p className="brandon_18">
                <span className="brandon_18_bold">9:00pm - </span>
                Arrive in San Diego
              </p>
            </div>
            <p className="brandon_18 margin_bottom_8">Select the number of guests:</p>
            <div className={classNames(s.selectContainer, 'margin_bottom_16')}>
              <Select
                onChange={handleOnChange}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    fontFamily: `brandon-grotesque, sans-serif`,
                    fontSize: '15px',
                    cursor: 'pointer',
                  }),
                  control: (provided) => ({
                    ...provided,
                    fontFamily: `brandon-grotesque, sans-serif`,
                    fontSize: '15px',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    fontFamily: `brandon-grotesque, sans-serif`,
                    fontSize: '15px',
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 10 }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#a39d97',
                    primary75: '#f6f5f0',
                    primary25: '#f6f5f0',
                    primary50: '#8c9489',
                  },
                })}
                options={options}
              />
            </div>
            <button
              style={{ display: 'block', width: '158px' }}
              className="button_grey_fill"
              onClick={handleOnBookClick}
            >
              {loading ? <Loader /> : 'Book Now'}
            </button>
          </div>
        </div>
      </section>
      <section className={classNames(s.green, s.info)}>
        <h1 className="garamond_61 white_text margin_bottom_16">Trip Information</h1>
        <div className="margin_bottom_8" style={{ maxWidth: '600px' }}>
          <ul className="brandon_18 " style={{ listStyleType: 'disc' }}>
            <li className="white_text">
              We will depart from Balboa Park in San Diego (free parking)
            </li>
            <li className="white_text">Everything except driver gratuity is included</li>
            <li className="white_text">
              We will have coolers in the van if you'd like to bring drinks or snacks
            </li>
            <li className="white_text">Passport, global entry card, or passport card required</li>
            <li className="white_text">
              Those with Global Entry can stay in the van during Mexico to US border crossing,
              otherwise we utalize the pedestrian lane
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};
